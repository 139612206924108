<template>
    <div class="revenue-chart">
      <div class="title-box">
        <p class="chart-title" v-if="chartParams">
            {{chartParams.name}}走势图
        </p>
      </div>
        <div id="chartBox" v-if="boss.analysisChart && boss.analysisChart.length"></div>
        <p class="no-data" v-else>暂无数据</p>
    </div>
</template>
<script>
	import table from '@/api/table'
	// import echarts from 'echarts'
	import {mapState} from 'vuex'
	// import util from '@/utils/util'
	export default {
		name:"analysis-chart",
		props:{
			chartParams:Object,
			timeParams:Object,
		},
		data(){
			return{
				xList:null,
				yList:null,
			}
		},
		computed:{
			...mapState(['userStatus','boss'])
		},
		watch:{
			chartParams:{
				handler(newVal,oldVal){
					if(!newVal){
						return
					}
					if( !!oldVal && newVal.filed === oldVal.filed){
						return
					}
					this.loadData()
				},
				deep:true,
				immediate:true,
			},
			timeParams:{
				handler(newVal,oldVal){
					let {chartParams} = this
					if(!newVal){
						return
					}
					if(!chartParams){
						return
					}
					if( !!oldVal && newVal.startTime === oldVal.startTime){
						return
					}
					this.loadData()
				},
				deep:true,
				immediate:true,
			},
			'userStatus.curs_node_id_2':{
				handler(){
          console.log("ok")
					let {chartParams} = this
					if(!chartParams){
						return
					}
					this.loadData()
				},
				deep:true,
				immediate:true,
			}
		},
		methods:{
			loadData(){
				this.getRevenueChartData().then(() =>{
					this.resolveChartData()
					this.renderChart()
				})
            },
			/**
			 * 获取营收分析7日走势图
			 */
			getRevenueChartData(){
				let {chartParams,timeParams} = this
				let chartType = !!chartParams?chartParams.filed:'consume_account'
				let {type,startTime,endTime} = timeParams
				let params = {
					chartType,
					timeType:type,
					startTime,
					endTime,
				}
				return new Promise((resolve,reject) =>{
					table.getRevenueAnalysisChart(params).then(res =>{
						this.$store.commit("updateAnalysisChart",res.data)
						resolve()
					}).catch(err =>{
						console.log("err",err.info)
						reject()
					})
				})
			},
			/**
			 * 处理图表数据
			 */
			resolveChartData(){
				let {analysisChart} = this.boss
				let xList = []
				let yList = []
				let yMaxDigit = 0;
				for(let item of analysisChart) {
					for(let key in item){
						let yDigit = parseInt(item[key],10).length
						yMaxDigit = yMaxDigit > yDigit ? yMaxDigit : yDigit
						xList.push(key)
						yList.push(item[key])
					}
				}
				yList.forEach(value => value = value/Math.pow(10,yMaxDigit))
				this.xList = xList
				this.yList = yList
			},
			/**
			 * 配置营业分析走势图
			 */
			renderChart(){
				let chart = this.$echarts.init(document.querySelector('#chartBox'))
				let {xList,yList} = this
				var color = '#0066ff'
				let areaBack = 'rgba(0,102,255,0.2)'
				let tooltipBack = 'rgba(50,50,50,0.6)'
				let YUnit = this.getChartYUnit()
				let YName = this.getChartYName()
				let option = {
					theme:'light',
					grid:{
						left:'5%',
						right:'6%',
						bottom:'3%',
						top:'10%',
						containLabel:true
					},
					xAxis:{
						type:'category',
						boundaryGap:false,
						data:xList,
						axisTick:{
							alignWithLabel:true
						},
						axisPointer:{
							snap:true,
						},
						axisLine:{
							show:false
						},
						axisTick:{
							show:false,
						},
					},
					yAxis:{
						type:'value',
						splitNumber:5,
						axisLine:{
							lineStyle:{
								color:'#dfdfdf'
							}
						},
						axisTick:{
							show:false,
						},
						axisLabel:{
							inside:true,
							color:'#333333'
						},
					},
					tooltip : {
						trigger: 'axis',
            textStyle: {
               color: "#FFFFFF" //设置文字颜色
            },
						backgroundColor:tooltipBack,
						formatter: function(params){
							let {axisValue,value} = params[0]
							let time = axisValue.split('.')
							let dateTime = time[0]+'月'+time[1]+'日'
							// return "日期："+dateTime+"<br/>"+YName + ' : ' + parseFloat(value).toLocaleString('en-US')+YUnit
							return "日期："+dateTime+"<br/>"+YName + ' : ' + value + YUnit
						}
					},
					series:[{
						data:yList,
						type:'line',
						smooth:true,
						areaStyle:{
							normal:{
								color:areaBack
							}
						},
						// 显示数值
						itemStyle:{
							normal:{
								color,
								lineStyle:{color},
								label:{show:false}
							}
						},
					}]
				};
				chart.setOption(option)
			},
			/**
			 * 走势图获取Y轴中文单位
			 */
			getChartYUnit(){
				let {chartParams} = this
				let filed = !!chartParams?chartParams.filed:'total_turnover'
				switch(filed){
					case 'consume_account':
						return '元'
						break
					case 'discount_dot':
						return '个'
						break
					case 'main_project':
						return '个'
						break
					case 'attach_project':
						return '个'
						break
					case 'room_consume':
						return '元'
						break
					case 'person_consume':
						return '元'
						break
					case 'room_count':
						return '个'
						break
					case 'person_count':
						return '人'
						break
				}
			},

			/**
			 * 走势图获取Y轴中文单位
			 */
			getChartYName(){
				let {chartParams} = this
				let filed = !!chartParams?chartParams.filed:'total_turnover'
				switch(filed){
					case 'consume_account':
						return '金额'
						break
					case 'discount_dot':
						return '点数'
						break
					case 'main_project':
						return '点数'
						break
					case 'attach_project':
						return '点数'
						break
					case 'room_consume':
						return '金额'
						break
					case 'person_consume':
						return '金额'
						break
					case 'room_count':
						return '数量'
						break
					case 'person_count':
						return '数量'
						break
				}
			}
		},
		mounted(){
		}
	}
</script>


<style lang="scss" type="text/scss" scoped>
    @import "~@/assets/style/charts.scss";
    .title-box{height: 80px}
</style>
